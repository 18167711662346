const chars = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ"; // remove "0OI1", easily confused for each other
const startAt = 79;

const encodeReferralCode = (numberToEncode) => {
  numberToEncode += startAt;
  let encoded = '';
  while (numberToEncode > 0) {
    const remainder = numberToEncode % 32;
    encoded = chars.charAt(remainder) + encoded;
    numberToEncode = Math.floor(numberToEncode / 32);
  }
  return "AUG" + encoded;
}

const decodeReferralCode = (encodedStr) => {
  let number = 0;
  const baseStr = encodedStr.substring(3); // Remove the "AUG" prefix

  for (let i = 0; i < baseStr.length; i++) {
    number = number * 32 + chars.indexOf(baseStr.charAt(i));
  }

  return number - startAt;
};

export {encodeReferralCode, decodeReferralCode};